import React, { useEffect, useState } from 'react';
import { HandleplanStyle } from './Handleplan.style';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import tempData from '../../Assets/apv.json'
import axios from 'axios';
import ReadOnlyAPV from '../APV/ReadOnlyAPV';
import SuccesCheckmark from '../../Components/SuccesCheckmark/SuccesCheckmark';

function Handleplan() {
  const { id } = useParams()
  const navigate = useNavigate(); // Get the navigation function
  const [selection, setSelection] = useState("name")
  const [client, setClient] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [explain, setExplain] = useState('');
  const [hideBtn, setHideBtn] = useState(false);
  const [clientId, setClientId] = useState('');
  const [data, setData] = useState(null);
  const [success, setSuccess] = useState(false);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
  const { register, handleSubmit, formState: { errors } } = useForm()

  const submitForm = (data) => {
      console.log(data);
      
    }

    useEffect(() => {
      const fetchData = async () => {
        try {
        
          const response2 = await axios.get(`https://api.kvægapv.eu/getApv/${id}`);
          setData(response2.data.content)
          setClientId(response2.data.clientId)
          console.log(response2.data);
        } catch (error) {
          console.log(error);
        }
      };
      fetchData();
      
      // Cleanup function
      return () => {
      
      };
    }, []);
    const updateAPV = async (id,content) => {
      console.log(id);
      try {
          const response = await axios.put(`https://api.kvægapv.eu/updateapv/${id}`, {
              content: JSON.stringify(content), // Ensure content is stringified
          });
          //console.log('APV updated successfully:', response.data);
                setTimeout(() => {
        setSuccess(true); // After 1 second, set success to true
        setTimeout(() => {
          setSuccess(false); // After 1 second, set success to false
        }, 1500);
      }, 200);
  
      } catch (error) {
          console.error('Update error:', error);
          throw error;
      }
  };

  const addDeadline = (sectionIndex, questionIndex, deadline) => {
    const newData = [...data];
    newData[sectionIndex].questions[questionIndex].deadline = deadline;
    setData(newData);

  };

  const addExplantion = (sectionIndex, questionIndex, explanation) => {
    const newData = [...data];
    newData[sectionIndex].questions[questionIndex].explanation = explanation;
    setData(newData);

  };

  const addResponsible = (sectionIndex, questionIndex, responsible) => {
    const newData = [...data];
    newData[sectionIndex].questions[questionIndex].responsible = responsible;
    setData(newData);
    console.log(responsible);
  };
    return (
        <HandleplanStyle>
          {success && <SuccesCheckmark />}
<form onSubmit={handleSubmit(submitForm)}>
   <div id='handleplanDiv'>


{data ? data.map((section, sectionIndex) => (
  <div key={sectionIndex}>
    {/* Check if there are questions with status "Udfordring" in the section */}
    {section.questions.some(question => question.status === "Udfordring") && (
      <h2>{section.heading}</h2>
    )}

    {section.questions.map((question, qIndex) => (
      question.status === "Udfordring" ? (
      <>
      <h4>{question.question}</h4>
        <article key={qIndex} className='handleplanArt'>
          
          <div>
            <h3>Beskriv udfordringen</h3>
            <textarea name="" id="" cols="30" rows="5" defaultValue={question.explanation} onChange={(e) => addExplantion(sectionIndex, qIndex, e.target.value)}></textarea>
          </div>
          <div className='deadlineDiv'>
            <h3>Deadline</h3>
            <div className='deadlineInputDiv'>
              <label htmlFor="" >Løses straks</label>
              <input type="radio" name="" id="" onChange={(e) => addDeadline(sectionIndex, qIndex, new Date().toLocaleDateString('en-GB'))}/>
              <label htmlFor="">Løses inden </label>
              <input type="date" name="" id="" defaultValue={question.deadline.split('/').reverse().join('-')} onChange={(e) => addDeadline(sectionIndex, qIndex, new Date(e.target.value).toLocaleDateString('en-GB'))} />
            </div>
          </div>
          <div>
            <h3>Ansvarlig</h3>
            <input placeholder='Ansvarlig' type="text" defaultValue={question.responsible} onChange={(e) => addResponsible(sectionIndex, qIndex, e.target.value)}/>
          </div>
        </article></>
      ) : null
    ))}
  </div>   
)) : <h2>Loading...</h2>}

<div className='btnDiv'>
<button onClick={() => updateAPV(id,data)}>Gem alt</button>
<button onClick={() => navigate(`/clientlookup/${clientId}`)}>Tilbage til kundeside</button>
<button style={{display: !isDesktop ? "none" : "block"}} onClick={() => {
  document.getElementsByTagName('header')[0].style.display = 'none'
  document.getElementsByClassName('btnDiv')[0].style.display = "none"
  document.getElementById('handleplanDiv').style.marginLeft = "2vw"
  document.getElementById('handleplanDiv').style.marginRight = "2vw"

 setHideBtn(true) 
 setTimeout(() => {
   window.print()
 }, 200);
 
  setTimeout(() => {
    setHideBtn(false)
  document.getElementsByTagName('header')[0].style.display = 'block'
  document.getElementsByClassName('btnDiv')[0].style.display = "block"
  document.getElementById('handleplanDiv').style.marginLeft = "0vw"
  }, 500);

}}>Print</button>
</div></div>
            </form> 
        </HandleplanStyle>
    );
}

export default Handleplan;
