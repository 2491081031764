import { styled } from "styled-components";

export const ForgotPasswordStyle = styled.article`
width: 20vw;
display: flex;
justify-content: center;
margin: 21vh 10vw;
/* border: solid 2px red; */
label{
    display: none;
}

button{
    background-color: #007297;
    border: none;
    padding: 2vh 0;
    width: 100%;
    border-radius: 20px;
    color: white;
    font-weight: bold;
    font-size: 17px;
    cursor: pointer;
    margin-bottom: 2vh;
}

input{
    display: block;
    border-radius: 20px;
    border-width: 1px;
    border-color: black;
    padding-top: 2vh;
    padding-bottom: 2vh;
    padding-left: 1vw;
    width: 94%;
    margin-bottom: 2vh;
}

h1{
    display: block;
    font-size: 58px;
    text-align: right;
    margin-right: 2vw;
}
span{
    display: block;
    text-align: center;

    width: 100%;
}

p{
    text-align: end;
    font-weight: bold;
    margin: 4vh 0;
    width: 100%;
    cursor: pointer;
}

img{
    height: 3vh;
    display: block;
}

.headingDiv{
display: grid;
justify-content: right;
margin-bottom: 7vh;
margin-right: 2vw;
}

@media screen and (max-width: 768px) {
    width: 50vw;
display: flex;
justify-content: center;
}
`