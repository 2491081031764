import React, { useEffect } from 'react'
import { LoginStyle } from './Login.style'
import LoginForm from '../../Components/Login/LoginForm'
import ResetPassword from '../../Components/ForgotPassword/ForgotPassword'
import image from "../../Assets/image.png"
import { useState } from 'react'
import SuccesCheckmark from '../../Components/SuccesCheckmark/SuccesCheckmark'

function Login() {
  const [isLogin, setIsLogin] = useState(true)
  useEffect(() => {
document.getElementById('forgotPass').addEventListener("click", function (e) {
  console.log(e.currentTarget === this); // logs `true`
  setIsLogin(false)
});

  }, []);

  useEffect(() => {
    if (isLogin) {
      document.getElementById('forgotPass').addEventListener("click", function (e) {
        console.log(e.currentTarget === this); // logs `true`
        setIsLogin(false)
      });
    }else{
          document.getElementById('forgotPassBackBtn')?.addEventListener("click", function (e) {
      console.log(e.currentTarget === this); // logs `true`
      setIsLogin(true)
    });
    }

      }, [isLogin]);
  return (
    <LoginStyle>
<section>
              {isLogin ? <LoginForm /> : <ResetPassword />}
               <footer>
                <p>Alle rettigheder forbeholdes {new Date().getFullYear()} © <a target='blank' href="https://fjordland.dk/">Fjordland</a></p>
            </footer>
</section>

           <section className='imageSection' style={{ backgroundImage: `url(${image})`}}></section>
    </LoginStyle>

  )
}

export default Login