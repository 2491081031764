import React, { useEffect, useState } from 'react';
import { HistoryStyle } from './History.style';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function History() {
  const [selection, setSelection] = useState("date")
  const navigate = useNavigate(); // Get the navigation function
  const [data, setData] = useState("")
    const [names, setNames] = useState([
        {
            "name": "Henrik Madsen",
            "date": "12/04/2024"
        },
        {
            "name": "John Doe",
            "date": "10/15/2023"
        },
        {
            "name": "Alice Smith",
            "date": "08/22/2022"
        },
        {
            "name": "Emily Johnson",
            "date": "06/30/2025"
        },
        {
            "name": "Michael Brown",
            "date": "04/18/2023"
        },
        {
          "name": "Sophia Garcia",
          "date": "09/12/2023"
      },
      {
          "name": "Daniel Rodriguez",
          "date": "11/25/2024"
      },
      {
          "name": "Olivia Martinez",
          "date": "03/07/2022"
      },
      {
          "name": "William Lee",
          "date": "07/19/2025"
      },
      {
          "name": "Emma Taylor",
          "date": "05/11/2024"
      },
      {
        "name": "Ethan Harris",
        "date": "02/14/2023"
    },
    {
        "name": "Isabella Clark",
        "date": "10/28/2024"
    },
    {
        "name": "Liam Wilson",
        "date": "08/06/2022"
    },
    {
        "name": "Ava Anderson",
        "date": "06/03/2025"
    },
    {
        "name": "Noah Martinez",
        "date": "01/17/2023"
    },
    {
        "name": "Oliver Thompson",
        "date": "09/21/2024"
    },
    {
        "name": "Sophia Davis",
        "date": "07/01/2022"
    },
    {
        "name": "Jackson Rodriguez",
        "date": "04/09/2025"
    },
    {
        "name": "Amelia Harris",
        "date": "11/13/2023"
    },
    {
        "name": "Elijah Martin",
        "date": "03/25/2022"
    }
    ]);
    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get('https://api.kvægapv.eu/getAllApv');
            setData(response.data);
            console.log(response.data);
          } catch (error) {
            console.log(error);
          }
        };
        fetchData();
        
        // Cleanup function
        return () => {
        
        };
      }, []);
    // Function to sort names array by name
    const sortByName = () => {
        const sortedNames = [...data].sort((a, b) => a.farmer?.localeCompare(b.farmer));
        setNames(sortedNames);
    };

    // Function to sort names array by date
    const sortByDate = () => {
        const sortedNames = [...data].sort((b, a) => new Date(a.createdAt) - new Date(b.createdAt));
        setNames(sortedNames);
    };

    useEffect(() => {
if (selection === "date") {
  sortByDate()
}else{
  sortByName()
}
      }, [selection,data])
    return (
        <HistoryStyle>
            
           {data ? <div className='pageHeader'>
            <h3>Viser {data ? data.length : 0} APV'er</h3>
                        <h2>Historik</h2>
            <select name="" id="" onChange={(e) => setSelection(e.target.value)}>
            <option value="date" >Dato</option>
              <option value="name" >Navn</option>
              
              
            </select>
          </div> : <h2 style={{position: "absolute", left: "38vw", top: "40vh"}}>Henter historikken...</h2>}

        { data &&  ( <section className='result'>
                
                {names.map((item, index) => (
                    <div key={index} onClick={() => navigate(`/editapv/${item.id}`)}>
                        <p>{item.farmer}</p>
                        <p>{new Date(item.createdAt).toLocaleDateString('en-GB')}</p>
                    </div>
                ))}
            </section>)}
        </HistoryStyle>
    );
}

export default History;
