import { styled } from "styled-components";

export const SearchStyle = styled.article`
margin-left: 4vw;
margin-right: 4vw;
form{
display: grid;  
span{
    text-align: center;
    font-weight: bold;
    margin-top: 1vh;
}
    input{
        text-align: center;
    font-size: 21px;
    border: none;
    border-bottom: solid black 2px;
    padding-bottom: 1vh;
}

article{
    width: 95%;
    display: grid;
    grid-template-columns: 20% 20% 60%;
    grid-gap: 2vw;
    margin-left: 0;
    margin-top: 2vh;
    button{
    background-color: #007297;
    border: none;
    border-radius: 6px;
    padding: 2vh 0;
    color: white;
    font-weight: bold;
    font-size: 17px;
    cursor: pointer;
    margin-bottom: 2vh;
    width: 100%;

}
}



}
h2{
        text-align: center;
    background-color: #007297;
    border: none;
    height: 5vh;
    padding: 2vh 0;
    color: white;
    font-weight: bold;
    }
.result{
    text-align: center;
    background-color: #007297;
    border: none;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    padding: 2vh 0;
    color: white;
    font-weight: bold;
    height: 60vh;
    overflow: auto;

    
    p{
        margin-bottom: 1vh;
        height: 4vh;
        font-size: 21px;
        font-weight: 400;
        border-bottom: solid white 2px;
    }
}
@media screen and (min-width: 768px) {
    margin: 0;
    margin-top: 6vh;
    margin-left: 12.5rem;;
    form{  

        span{
            margin: 2vh 0;
            font-size: 26px;
        }
            input{
            font-size: 46px;
            
        }
        article{
    width: 50%;
    display: grid;
    grid-template-columns: 20% 20% 60%;
    grid-gap: 2vw;
    margin-left: 20rem;
    margin-top: 2vh;
    button{
    background-color: #007297;
    border: none;
    border-radius: 6px;
    padding: 2vh 0;
    color: white;
    font-weight: bold;
    font-size: 17px;
    cursor: pointer;
    margin-bottom: 2vh;
    width: 100%;

}
}
    }
    .result{
        width: 84vw;
        height: 56vh;
    
            p{
                font-size: 30px;
            }
        
    }

    article{
        margin-bottom: 4vh;
        button{
            margin: 4vh 0;
            font-size: 46px;
        }
    }

    h2,p,button{
        font-size: 40px;
    }
    }
`