import { styled } from "styled-components";

export const NewClientStyle = styled.article`
margin-left: 4vw;
margin-right: 4vw;  
button{
    background-color: #007297;
    border: none;
    border-radius: 6px;
    margin-top: 1rem;
    padding: 1vh 0;
    color: white;
    font-weight: bold;
    font-size: 17px;
    cursor: pointer;
    width: 70%;
    margin-top: 1rem;
    border: solid 1px white;

}
form{
display: grid;   
span{
    text-align: center;
    font-weight: bold;
    margin-top: 1vh;
}
section{
    display: grid;
    grid-template-columns: 1fr;
}

.cvrInput
,.dateInput
,.clientInput
,.emailInput
,.phoneInput{
    margin-bottom: 2vh;
    text-align: center;
    background-color: #007297;
    border: none;
    border-radius: 6px;
    padding: 2vh 0;
    color: white;
    font-weight: bold;
    font-size: 17px;
    span{
        display: block;
    }
        input{
        text-align: center;
        color: white;
    font-size: 25px;
    border: none;
    border-bottom: solid white 2px;
    padding-bottom: 1vh;
    background-color: #007297;
    width: 80%;

}
input::placeholder{
    color: #FFFFFF;
    opacity: 54%;
}
}

.listSec{
    margin-bottom: 2vh;
    text-align: center;
    background-color: #007297;
    border: none;
    border-radius: 6px;
    color: white;
    font-weight: bold;
    font-size: 17px;
    h2{
        height: 10vh;
    }
}

.listArt{
    overflow: auto;
    width: auto;
}

.listDiv{
    display: flex;
    justify-content: space-between;
    border-bottom: solid white 1px;
    padding-bottom: 2vh;
    padding-top: 2vh;
    padding-left: 2vw;
    padding-right: 2vw;
    p{
        margin: 0;
        padding: 0; 
    }
    input{
        margin: 0;
        padding: 0;
    }
}
.bilagSec{

    text-align: center;
    background-color: #007297;
    border: none;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    padding: 2vh 0;
    color: white;
    font-weight: bold;
    font-size: 17px;
    height: 50vh;
    h2{
        height: 10vh;
        
    }
    p{
        border-bottom: solid 2px white;
        margin-top: 1rem;
        padding-bottom: 1rem;
    }
}

.bilagArt{
    overflow: auto;
    
}

.bilagDiv{
    display: grid;
    grid-template-columns: 80% 20%;
    justify-items: center;
    border-bottom: solid white 1px;
    padding-bottom: 2vh;
    padding-top: 2vh;
    text-align: center;
    
    p{
        margin-left: 8vh;
    }
    button{
        margin-right: 6vw;
        border: none;
        width: 10vw;
        height: 4vh;
        border-radius: 6px;
    }
}
.uploadBtn{
    border: none;
    background-color: #007297;
    color: #FFFFFF;
    font-size: 21px;
    font-weight: bold;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    height: 6vh;
    padding: 2vh, 4vw;
}

.btnArt{
    width: 98%;
    
    justify-items: right;
    grid-template-columns: 30% 70%;
    grid-gap: 1vw;
    margin-left: 1vw;
    button{
    background-color: #007297;
    border: none;
    border-radius: 6px;
    padding: 2vh 0;
    color: white;
    font-weight: bold;
    font-size: 17px;
    cursor: pointer;
    width: 100%;

}
.clearAll{
    background-color: #CF4040;
}
}



}

@media screen and (min-width: 768px) {
    margin: 1vh 0;
    margin-left: 12.5rem;;
    form{
        display: grid;   
        grid-template-columns: 1fr 1fr;
        grid-gap: 1vw;
        width: 84vw;
        span{
            text-align: center;
            font-weight: bold;
            margin-top: 1vh;
        }
        section:first-of-type{
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 1vw;
        }
    }

    .listSec{
        margin-bottom: 2vh;
        text-align: center;
        background-color: #007297;
        border: none;
        border-radius: 6px;
        padding: 2vh 0;
        color: white;
        font-weight: bold;
        font-size: 17px;
        height: 50vh;
        h2{
            height: 10vh;
        }
    }
    
    .listArt{
        overflow: auto;
        
    }
    
    .listDiv{
        display: flex;
        justify-content: right;
        border-bottom: solid white 1px;
        padding-bottom: 2vh;
        padding-top: 2vh;
        p{
            margin-right: 30vw;
            
        }
        input{
            margin-right: 8vw;
        }
    }
    .bilagSec{
    
        text-align: center;
        background-color: #007297;
        border: none;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
        padding: 2vh 0;
        color: white;
        font-weight: bold;
        font-size: 17px;
        height: 40vh;
        overflow: auto;
        h2{
            height: 10vh;
        }
    }
    
    .bilagArt{
        overflow: auto;
    }
    
    .bilagDiv{
        display: grid;
        grid-template-columns: 80% 20%;
        justify-items: center;
        border-bottom: solid white 1px;
        padding-bottom: 2vh;
        padding-top: 2vh;
        text-align: center;
        p{
            margin-left: 6vh;
        }
        button{
            margin-right: 6vw;
            border: none;
            width: 8vw;
            height: 4vh;
            border-radius: 6px;
        }
    }

    .btnArt{
        display: flex;
        justify-content: right;
    }
}
`