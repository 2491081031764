import React, { useEffect, useState } from 'react';
import { NewClientStyle } from './NewClient.style';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import SuccesCheckmark from '../../Components/SuccesCheckmark/SuccesCheckmark';

function NewClient() { 
  const { register, handleSubmit, formState: { errors } } = useForm()
  const navigate = useNavigate(); // Get the navigation function
  const [client, setClient] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [cvr, setCvr] = useState('');
  const [isAPV, setIsAPV] = useState(false);
  const [isAutoFill, setIsAutoFill] = useState(false);
  const [isSubmitBtn, setIsSubmitBtn] = useState(false);
  const [autoFillData, setAutoFillData] = useState([]);
  const [showPass, setShowPass] = useState(false);
  const [password, setPassword] = useState("");
  const [data, setData] = useState("");
  const [id, setId] = useState(0);
  const [success, setSuccess] = useState(false);
  function splitFullName(fullName) {
    const parts = fullName.split(' ');
    let firstName = parts[0];
    let middleName = '';
    let lastName = '';

    // If there are more than two parts, assume the last part as the last name
    if (parts.length > 1) {
        lastName = parts.pop(); // Get the last part as last name
        // If there are still more parts, assume the first part as the first name
        if (parts.length > 1) {
            firstName = parts.shift(); // Get the first part as first name
            // If there are more parts remaining, they are middle names
            middleName = parts.join(' '); // Join the remaining parts as middle name
        }
    }

    return {
        firstName: firstName,
        middleName: middleName,
        lastName: lastName
    };
}


  const submitForm = (data) => {
    if (isSubmitBtn) {
      
    
if (!isAutoFill) {
  const nameParts = splitFullName(client);
    const firstName = nameParts.firstName;
    const middleName = nameParts.middleName;
    const lastName = nameParts.lastName;

    console.log(email, firstName, middleName, lastName, data.cvr, phone);
    if (isAPV) {
      createNewAPV(data.apvName,"","",1,email, firstName, middleName, lastName, data.cvr, phone)
    }else{
      createNewClient(email, firstName, middleName, lastName, data.cvr, phone);
    }

}
    }
    
}

    const [bilag, setBilag] = useState([])

    
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get('https://api.kvægapv.eu/getAllLists');
          const response1 = await axios.get('https://api.kvægapv.eu/getMax');
          setData(response.data);
          setId(response1.data.nextId)
          console.log(response.data);
          console.log(response1.data.nextId);
        } catch (error) {
          console.log(error);
        }
      };
  
      fetchData();

    }, []);

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response4 = await axios.get(`https://api.kvægapv.eu/getBilag/${id}`);
          let filenames = []
          response4.data.allBilag.map((item) => {
            filenames.push(item.filename)
          })
          setBilag(filenames)
          console.log(response4.data.allBilag);
          
        } catch (error) {
          console.log(error);
        }
      };
  
      fetchData();

    }, [data]);

    const handleCVRFetch = async () => {
      setIsAutoFill(true)
      try {
        const response = await axios.get(`https://cvrapi.dk/api?search=${document.getElementById('cvr').value}&country=dk`);
        setAutoFillData(response.data);
        setEmail(response.data.email)
        setPhone(response.data.phone)
        setClient(response.data.owners[0].name)
        console.log(response.data);

      } catch (error) {
        console.log(error);
      }
    };
// Function to handle input changes and sanitize them
const handleClientChange = (event) => {
  // Sanitize input to prevent XSS
  const sanitizedValue = event.target.value.replace(/</g, "&lt;").replace(/>/g, "&gt;");
  setClient(sanitizedValue);
};

const handlePasswordChange = (event) => {
  setPassword(event.target.value);
};

const handleEmailChange = (event) => {
  // Sanitize input to prevent XSS
  const sanitizedValue = event.target.value.replace(/</g, "&lt;").replace(/>/g, "&gt;");
  setEmail(sanitizedValue);
};
console.log(email, phone, client);

const handlePhoneChange = (event) => {
  // Sanitize input to prevent XSS
  const sanitizedValue = event.target.value.replace(/</g, "&lt;").replace(/>/g, "&gt;");
  setPhone(sanitizedValue);
};
const createNewClient = async (email,firstname,middlename,lastname,cvr,phone) => {
  const generatedPass = password
  sessionStorage.setItem(`tempPass${cvr}`, JSON.stringify(generatedPass))
  console.log(JSON.parse(sessionStorage.getItem(`tempPass${cvr}`)));
  if (email && phone && firstname && cvr) {
      try {
      const response = await axios.post('https://api.kvægapv.eu/create-client', {
        email:email,
        password:password,
        firstname:firstname,
        middlename:middlename || null,
        lastname:lastname,
        cvr:cvr,
        phone:phone
      });
      console.log('Client created successfully:', response.data);
            setTimeout(() => {
        setSuccess(true); // After 1 second, set success to true
        setTimeout(() => {
          setSuccess(false); // After 1 second, set success to false
        }, 1500);
      }, 200);
  } catch (error) {
    alert(error.response.data);
      console.error('Creation error:', error);
      
  }
  }else{
    alert(`Du mangler stadig:${!email ? "\n - Email" : ""}${!phone ? "\n - Telefonnummeret" : ""}${!firstname ? "\n - Navn" : ""}`)
  }

};

const createNewAPV = async (name,clientId,content,createdBy,email,firstname,middlename,lastname,cvr,phone) => {
  if (email && phone && firstname && cvr) {
  try {
    const response = await axios.post('https://api.kvægapv.eu/create-client', {
      email:email,
      password:password,
      firstname:firstname,
      middlename:middlename || null,
      lastname:lastname,
      cvr:cvr,
      phone:phone
    });
    console.log('Client created successfully:', response.data);
          setTimeout(() => {
        setSuccess(true); // After 1 second, set success to true
        setTimeout(() => {
          setSuccess(false); // After 1 second, set success to false
        }, 1500);
      }, 200);

      const response1 = await axios.post('https://api.kvægapv.eu/createapv', {
          name: name,
          clientId: response.data.id,
          content: JSON.stringify({ "APV": [] }), // Ensure content is stringified
          createdBy: JSON.parse(sessionStorage.getItem('user')).id
      });
      console.log('APV created successfully:', response.data);
            setTimeout(() => {
        setSuccess(true); // After 1 second, set success to true
        setTimeout(() => {
          setSuccess(false); // After 1 second, set success to false
        }, 1500);
      }, 200);
      navigate(`/newapv/id/${response1.data.id}`)
  } catch (error) {
      console.error('Creation error:', error);
      throw error;
  }
}else{
  alert(`Du mangler stadig:${!email ? "\n - Email" : ""}${!phone ? "\n - Telefonnummeret" : ""}${!firstname ? "\n - Navn" : ""}`)
}
};

const handleFileUpload = event => {
  const file = event.target.files[0];
  const targetFolder = id
  let isDublicate = false
  if (!file) return;
  console.log(file.name);
  bilag.map((item) => {
    console.log(item);
    
      if (item === file.name) {
    alert("Du har allerede en fil med det navn!")
    isDublicate = true
  }
  })

  setTimeout(() => {
    if (!isDublicate) {
       const formData = new FormData();
  formData.append('file', file);

  fetch('https://api.kvægapv.eu/fileUpload', {
    headers: {
      'filename': file.name,
      'targetfolder':"/" + targetFolder,
      'clientid': id
    },
    method: 'POST',
    body: formData
  })
  .then(response => response.json())
  .then(data => {
    if (data.status === 'success') {
      setBilag([file.name, ...bilag]);
      setTimeout(() => {
        setSuccess(true); // After 1 second, set success to true
        setTimeout(() => {
          setSuccess(false); // After 1 second, set success to false
        }, 1500);
      }, 200);
    } else {
      alert('Error uploading file: ' + data.description);
    }
  })
  .catch(error => {
    console.error('Upload error:', error);
    alert('Upload failed: ' + error.message);
  });
    }
  }, 500);
 
};


const handleFileDelete = filename => {
  fetch(`https://api.kvægapv.eu/fileDelete?filename=${encodeURIComponent(filename)}&clientId=${encodeURIComponent(id)}`, {
    method: 'DELETE'
  })
  .then(response => response.json())
  .then(data => {
    if (data.status === 'success') {
      setBilag(bilag.filter(item => item !== filename));
      
      setTimeout(() => {
        setSuccess(true); // After 1 second, set success to true
        setTimeout(() => {
          setSuccess(false); // After 1 second, set success to false
        }, 1500);
      }, 200);
    } else {
      alert('Error deleting file: ' + data.description);
    }
  })
  .catch(error => {
    console.error('Delete error:', error);
    alert('Delete failed: ' + error.message);
  });
};

const handleCheckboxChange = () => {
  // Select all checkboxes on the page
  const checkboxes = document.querySelectorAll('input[type="checkbox"]');

  // Check if all checkboxes are unchecked
  const allUnchecked = Array.from(checkboxes).every(checkbox => !checkbox.checked);

  if (allUnchecked) {
    // Set all checkboxes to unchecked (just to ensure they are all false)
    checkboxes.forEach(checkbox => {
      checkbox.checked = false;
    });
    setIsAPV(false);
  } else {
    setIsAPV(true);
  }
};


const handlePasswordGen = () => {
  const words = [
    "Holstein",          // Ko race
    "Jersey",            // Ko race
    "Angus",             // Ko race
    "Hereford",          // Ko race
    "Landrace",          // Grise race
    "Yorkshire",         // Grise race
    "Duroc",             // Grise race
    "Hampshire",         // Grise race
    "NewHolland",       // Traktor mærke
    "Fendt",             // Traktor mærke
    "Høst",              // Landbrugsaktivitet
    "Vanding",           // Landbrugspraksis
    "Gødning",           // Landbrugsmateriale
    "Hø",                // Foder
    "Korn",              // Foder
    "Hvede",             // Afgrøde
    "Majs",              // Afgrøde
    "Traktor",           // Landbrugsmaskine
];

  const specialCharacters = "!+-?";

  const getRandomWord = () => {
    return words[Math.floor(Math.random() * words.length)];
  };

  const getRandomSpecialCharacter = () => {
    return specialCharacters[Math.floor(Math.random() * specialCharacters.length)];
  };

  const getRandomNumber = () => {
    return Math.floor(Math.random() * 10);
  };

  let generatedPassword = "";
  for (let i = 0; i < 2; i++) {
    generatedPassword += getRandomWord();
    if (i < 1) {
      generatedPassword += getRandomSpecialCharacter();
    }
  }
  generatedPassword += getRandomNumber();

  setPassword(generatedPassword);
};
    return (
        <NewClientStyle>
          {success && <SuccesCheckmark />}
{data ? <form id='myForm' onSubmit={handleSubmit(submitForm)}>
          <div>
          <div className='emailInput'>
  <h2>Email</h2>
  <input 
    type="text" 
    name="email" 
    id='email' 
    placeholder='Skriv email' 
    value={email} // Bind input value to state
    onChange={handleEmailChange} // Call sanitization function on change
    onBlur={handleEmailChange} // Additional sanitization on blur
    
  />
  {errors.email && <span>Du skal skrive emailen</span>}
</div>
<div className='cvrInput'>

<h2>Kodeord</h2>
<input type={showPass ? "text" : "password"} name="password" id='password' value={password} onChange={handlePasswordChange} placeholder='Skriv eller gener kodeord' />
{!password && <span>Du skal skrive eller generere et kodeord!</span>} 
<br />        
<button onClick={handlePasswordGen}>Tilfældig kodeord</button>
<button onClick={() => setShowPass(showPass ? false : true)}>{showPass ? "Skjul kodeord" : "Vis kodeord"}</button>
<br />
</div>
          </div>

   
              <section >
              {/* <div className='dateInput'>
                <h2>Dato for besøg</h2>
              <input type="date" name="date" id='date' placeholder='Søg..' {...register('date', {required: true})}/>
              {errors.date && <span>Du skal angive datoen for besøg!</span>}
              </div> */}
              <div className='clientInput'>
  <h2>Evt. Navn på APV</h2>
    <input type="text" {...register('apvName')} />
  {errors.apvName && <span>Du skal skrive navnet på landmanden</span>}
</div>

<div className='cvrInput'>

<h2>CVR</h2>
<input type="number" name="cvr" id='cvr'  placeholder='Skriv CVR' {...register('cvr', {required: true})}/>
{errors.cvr && <span>Du skal skrive CVR nummeret!</span>} 
<br />       
<button onClick={(e) => handleCVRFetch(e)}>Søg og udfyld</button>

<br />
</div>

              <div className='clientInput'>
  <h2>Landmand</h2>
  <input 
    type="text" 
    name="client" 
    id='client' 
    placeholder='Skriv fulde navn' 
    value={client} // Bind input value to state
    onChange={handleClientChange} // Call sanitization function on change
    onBlur={handleClientChange} // Additional sanitization on blur
  />
  {errors.client && <span>Du skal skrive navnet på landmanden</span>}
</div>


<div className='phoneInput'>
  <h2>Telefonnummer</h2>
  <input 
    type="tel" 
    name="phone" 
    id='phone' 
    placeholder='Skriv nummer' 
    value={phone} // Bind input value to state
    onChange={handlePhoneChange} // Call sanitization function on change
    onBlur={handlePhoneChange} // Additional sanitization on blur
  />
  {errors.phone && <span>Du skal skrive telefon nummeret</span>}
</div>

              </section>
              <section className='listSec'>
                <h2>Lister</h2>
                <article className='listArt'>
                  {
                    data.map((item,index) =>(
                      <div className='listDiv'>
                  <p>{item.name}</p>
                  <input id={`input${index}`} type="checkbox" onChange={handleCheckboxChange}/>
                </div>
                    ))
                  }
                </article>
                
              </section>
              <div  className="bilagSec">
            <h3>Bilag</h3>
            <div>
              <input type="file" onChange={(e) => {
                if (!document.getElementById('cvr').value) {
                  alert('Du kan ikke uploade uden at skrive CVR nummer')
                  return false
                }else{
                  setCvr(document.getElementById('cvr').value)
                  handleFileUpload(e)
                }
                }} />
            </div>
            {bilag.length > 0 ? (
              bilag.map((file, index) => (
                <div key={index}>
                  <p>{file}</p>
                  <button style={{width: "25%", marginBottom: "1rem", backgroundColor: "white", color: "black"}} onClick={() => {
                    if (window.confirm("Er du sikker?")) {
                      handleFileDelete(file)
                    }}}>Slet</button>
                </div>
              ))
            ) : (
              <p>Ingen bilag endnu</p>
            )}
          </div>
                    <br />
              <article className='btnArt'>
                <button className='clearAll' type='reset' onClick={() => {
                  setClient("")
                  setEmail("")
                  setPhone("")
                  setIsAPV(false)
                }} >Ryd alt</button>
                <button onClick={() => {
                  setIsSubmitBtn(true)
                  setIsAutoFill(false)
                  setIsAPV(false)}}>Opret ny kunde</button>
                        {isAPV ? <button onClick={() => {
                          
                          setIsSubmitBtn(true)
                          setIsAutoFill(false)
                          setIsAPV(true) 
                          

                          }}>Opret kunde og APV</button> : null}
                        
              </article>

            </form> : <h2 style={{position: "absolute", left: "50vw", top: "40vh", right: "50vh"}}>Gør tingene klar...</h2>}
        </NewClientStyle>
    );
}

export default NewClient;
