import { styled } from "styled-components";

export const HandleplanStyle = styled.section`
margin-top: 4vh;
margin-left: 12.5rem;
h2{
    margin-top: 2vh;
    border-bottom: solid black 2px;
    width: fit-content;
    margin-bottom: 2vh;
}
h4{
    margin-top: 2vh;
    width: fit-content;
    
}
.handleplanArt{
    display: grid;
    grid-template-columns: 35% 40% 25%;
    border: solid 1px black;
    width: fit-content;
    h3{
        border-bottom: solid black 1px;
        margin-bottom: 1vh;
    }
    textarea{
        font-size: 20px;
        width: 100%;

    }
    div{
         
        border-right: solid black 1px; 
        padding: 1vh 1vw;
    }
    span{
        display: flex;
        justify-content: space-between;
               p{
        height: 5vh;
    } 

    }
    input{
        width: 100%;
        height: 4vh;
        margin-top: 4vh;

        text-align: center;
        border: none;
        border-bottom: solid black 1px;
        font-size: 20px;
    }


    .deadlineInputDiv{    
    display: grid;
    grid-template-columns: 30% 60%;
    justify-items: center;
    border-right: none;
        label{
            padding-top: 4vh;
        width: 7vw;
    }
    input:first-of-type{  
        height: 2vh;
        margin-top: 4vh;
    }
    input:last-of-type{  
        margin-left: 4vw;
        height: 3vh;
        margin-top: 4vh;
        width: 16.5vw;
    }
}

}
button{
    background-color: #007297;
    border: none;
    border-radius: 6px;
    margin-top: 1rem;
    padding: 1vh 0;
    color: white;
    font-weight: bold;
    font-size: 17px;
    cursor: pointer;
    width: 50%;
    margin-top: 1rem;
    border: solid 1px white;
}
@media screen and (max-width: 768px) {
margin: 0;
padding: 1rem;

.handleplanArt{
    display: grid;
    grid-template-columns: 1fr;
    border: solid 1px black;
    width: 100%;
    h3{
        border-bottom: solid black 1px;
        margin-bottom: 1vh;
    }
    textarea{
        font-size: 20px;
        width: 100%;

    }
    div{
         
        border-right: solid black 1px; 
    }
    span{
        display: flex;
        justify-content: space-between;
               p{
        height: 5vh;
    } 

    }
    input{
        width: 90%;
        height: 4vh;
        margin-top: 1vh;
        margin-left: 1rem;


        text-align: center;
        border: none;
        border-bottom: solid black 2px;
        font-size: 20px;
    }


    .deadlineInputDiv{    
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    border-right: none;
        label{
            padding-top: 4vh;
        width: max-content;
    }
    input:first-of-type{  
        height: 2vh;
        margin-top: 4vh;
    }
    input:last-of-type{  
        margin-left: 1vw;
        height: 3vh;
        margin-top: 4vh;
        margin-right: 3vw;
        width: max-content;
    }
}

}
button{
    background-color: #007297;
    border: none;
    border-radius: 6px;
    margin-top: 1rem;
    padding: 1vh 0;
    height: 5rem;
    color: white;
    font-weight: bold;
    font-size: 17px;
    cursor: pointer;
    width: 100%;
    margin-top: 1rem;
    border: solid 1px white;
}
}
`