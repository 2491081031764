import React, { useEffect, useState } from 'react';
import { ResetPasswordStyle } from './ResetPassword.style';
import { useForm } from 'react-hook-form';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import image from "../../Assets/fjordland_logo_fritlagt.png";

function ResetPassword() {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const [passwordError, setpasswordError] = useState(false);
    const [loginError, setLoginError] = useState(false);
    const [bannedError, setBannedError] = useState(false);
    searchParams.get("token")
    console.log(searchParams.get("token"));
    const submitForm = async (data) => {

        try {
            console.log('Submitting data:', data); // Log data to verify it is correct

            const response = await axios.post('https://api.kvægapv.eu/reset-password', {
                newPassword: data.password,
                token: searchParams.get("token")
            });
            alert(`Dit kodeord er nu nulstillet!`)
            navigate('/')
        } catch (error) {
            alert('Der skete en fejl, prøv igen senere..')
            console.error('Reset error:', error);
        }
    };

    return (
        <ResetPasswordStyle>
            <form onSubmit={handleSubmit(submitForm)}>
                <h1>AgroAPV</h1>
                <div className='headingDiv'>
                    <img src={image} alt="logo" />
                    
                </div>
                <h4>Nulstil din kode</h4>
                <label htmlFor="password">Din password</label>
                <input type="text" name="password" id='password' placeholder='password' {...register('password', { required: true })} />
                {errors.password && <span>password skal udfyldes!</span>}
                <button type='submit'>Gem nyt kodeord</button>
                <button onClick={() => navigate('/')}>Til forsiden</button>
                {/* <NavLink to={'/dashboard'}>Konsulent</NavLink>
                <NavLink to={'/clientpage'}>Landmand</NavLink> */}
            </form>
        </ResetPasswordStyle>
    );
}

export default ResetPassword;
