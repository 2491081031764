import React from 'react'
import { SpinnerStyle } from './Spinner.style'

function Spinner() {
  return (
    <SpinnerStyle>
    <div className="spinner">
    <div className="loader"></div>
  </div>
  </SpinnerStyle>
  )
}

export default Spinner