import { styled } from "styled-components";

export const SpinnerStyle = styled.div`
      .spinner{
        position: fixed;
          left: 50vw;
          right: 50vw;
          top: 50vh;
          
      }
        .loader {
          border: 16px solid #f3f3f3;
          border-radius: 50%;
          border-top: 16px solid #3498db;
          width: 60px;
          height: 60px;
          animation: spin 2s linear infinite;
          
        }

        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
`