import { styled } from "styled-components";

export const HistoryStyle = styled.article`
margin-left: 4vw;
margin-right: 4vw;  
.pageHeader{
    color: white;
    display: grid;
    grid-template-columns: 1fr;
    text-align: center;
    background-color: #007297;
    border: none;
    height: fit-content;
    padding: 2vh 0;
    margin-right: -0.5vw;
 h2{
    color: white;
    font-weight: bold;
    text-align:center;
    margin-right: 0;
    padding: 1rem;
    } 
    select{
        margin-left: 8.5rem;
        margin-right: 0;
        width: 5rem;
        font-size: 17px;
    }  
}

.result{
    text-align: center;
    background-color: #007297;
    border: none;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    padding: 2vh 0;
    color: white;
    font-weight: bold;
    height: 75vh;
    overflow: auto;

    div{
        border-bottom: solid white 2px;
        display: flex;
        justify-content: space-between;
        padding: 0 2vw;
        
        p{
            margin-top: 1vh;
        margin-bottom: 1vh;
        height: 4vh;
        font-size: 21px;
        font-weight: 400;
    }
    }
}

@media screen and (min-width: 768px) {
    margin: 0;
    margin-top: 1vh;
    margin-left: 12.5rem;

    .result{
        width: 84vw;
        height: 86vh;
        div{
            p{
                font-size: 40px;
                margin: 2vh 1vw;
            }
        }
    }

    .pageHeader{
        width: 83vw;
        display: grid;
        grid-template-columns: 2fr 5fr 1fr;
        h3{
            margin: 2vh;
            color: white;
            font-weight: bold;
            } 
     h2{
        color: white;
        font-weight: bold;
        text-align: center;
        } 
        select{
            font-size: 27px;
            width: fit-content;
            text-align: center;
            margin-right: 2rem;
        }  
    }

    h2{
        font-size: 46px;
    }
    }
}
`