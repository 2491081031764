import { styled } from "styled-components";


export const EditAPVStyle = styled.section`
margin-top :1vh ;
text-align: center;

#realtimeBtn{ 
    margin: 0;
    position: fixed;
    z-index: 1000;
    bottom: 31vh;
    left: 3.5vh;
    width: 8vw;
    border: none;

    img{
        height: 5vh;
    }
}
.modal{
    position: fixed;
    left: 1.4vw;
    top: 18rem;
    background-color: white;
    border: solid black 1px;
    height: auto;
    width: 9rem;
    z-index: 2;
    border: none;
    .modal-content{
        background-color: #007297;
        line-height: 2rem;
        color: white;
        font-weight: bold;
        height: 19rem;
        width: auto;
        overflow-y: scroll;
        overflow-x: hidden;
        scrollbar-width: none;
        font-size: 15px;
        p{
            border-bottom: white 2px solid;
            
        }

    }
}


#toTop{
position: fixed;
width: fit-content;
height: 6vh;
bottom: 20px;
right: 20px;
background-color: rgba(0,0,0,0);
border: none;
font-size: 25px;
}

button{
    display: block;
    text-align: center;
    width: 90%;
    margin-left: 4vw;
    border-radius: 6px;
    height: 6vh;
    font-size: 17px;
    border: solid black 1px;
    margin-bottom: 1vh;
}
.mobileForm{

    
    div{
        background-color: #007297;
        border-radius: 6px;
        padding-bottom: 2vh;
        padding-top: 2vh;
        margin-bottom: 2vh;
        p{
            margin-bottom: 2vh;
            font-size: 17px;
            font-weight: bold;
        }
    }
    
    h2{
        border-bottom: solid black;
        margin-bottom: 4vh;
        margin-top: 6vh;
    }
    
    
    input{
        width: 90%;
        height: 4vh;
        margin-bottom: 2vh;
        font-size: 17px;
    }
}
.btnDiv{
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 1rem;
    gap: 0.3rem;
    position: fixed;
        left: 1.8vw;
        top: 42rem;
        z-index: 1;
        button{
        width: 4rem;
        height: 4rem;
        margin: 0;
        border: none;
        img{
            height: 3rem;
        }
    }
}
.btnDiv2{
    #toTop{

    }
    #modalBtn{
        z-index: 2;
        background-color: #007297;
        color: white;
        position: fixed;
        margin: 0;
        left: 3.5vw;
        top: 14rem;
        border: none;
        width: fit-content;
        font-weight: bold;
        font-size: 25px;
    }
}
.desktopForm{
    width: 80vw;
    margin-left: 12.5rem;
    margin-bottom: 10rem;
    .categoryDiv{
    display: grid;
    grid-template-columns: 60% 13% 13% 14%;
    p{
        padding-top: 0.5vh;
    }
}

.headingArt{
        display: grid;
        grid-template-columns: 10% 50% 13% 13% 13%;
        border: solid 1px black;
        border-top: none;

        div{
            padding: 1vh 1vw;
            
        }

        span{
            display: flex;
            justify-content: space-between;
                   p{
                    
            height: fit-content;
            text-align: left;
            
        } 


        }
        input{
            width: 100%;
            height: 5vh;
            border: none;
            border-bottom: solid black 1px;
            font-size: 23px;
        }
}

.handleplanArt{
    display: grid;
    grid-template-columns: 30% 30% 20% 20%;
    border: solid 1px black;
    border-top: none;
    textarea{
        font-size: 20px;
    }
    div{
         
        border-right: solid black 1px; 
    }
    span{
        display: flex;
        justify-content: space-between;
               p{
        height: 5vh;
    } 

    }
    input{
        width: 100%;
        height: 4vh;
        margin-top: 4vh;
        width: 12vw;
        border: none;
        border-bottom: solid black 1px;
        font-size: 23px;
    }

    button{
        width: 11.5vw;
        height: 10vh;
        margin-top: 1vh;
    }
    .deadlineInputDiv{    
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
        label{
            padding-top: 4vh;
        width: 10vw;
    }
    input:first-of-type{  
        height: 2vh;
        margin-top: 4vh;
    }
    input:last-of-type{  
        height: 3vh;
        margin-top: 4vh;
        width: 10vw;
    }
}

}
.newQuestion{
    margin: 0;
    height: 5vh;
    width: 100%;
}
}

@media screen and (max-width: 768px) {
    margin: 0;
    .btnDiv{
    grid-template-columns: 1fr 1fr 1fr 1fr;
    width: 1rem;
    gap: 0.3rem;
    position: fixed;
        left: 5rem;
        top: 37rem;
        z-index: 1;
    button{
        width: 3rem;
        height: 3rem;
        margin: 0;
        border: none;
        img{
            height: 2rem;
        }
    }
}

.modal{
    position: fixed;
    left: 0rem;
    top: 0rem;
    background-color: white;
    border: solid black 1px;
    height: auto;
    width: 23.5rem;
    z-index: 2;
    border: none;
    .modal-content{
        border: solid black 1px;
        padding: 1rem;
        background-color: #007297;
        line-height: 2rem;
        color: white;
        font-weight: bold;
        height: 34rem;
        width: auto;
        overflow-y: scroll;
        overflow-x: hidden;
        scrollbar-width: none;
        font-size: 15px;
        p{
            border-bottom: white 2px solid;
            
        }

    }
}
}

`