import React, { useState } from 'react';
import { SearchStyle } from './Search.style';
import { useForm } from 'react-hook-form';
import { Navigate, useNavigate } from 'react-router-dom';
import axios from 'axios'; // Import Axios

function Search() {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [searchResults, setSearchResults] = useState([""]); // State to store search results
    const [getAll, setGetAll] = useState(false);
    const navigate = useNavigate(); // Get the navigation function

    const submitForm = async (data) => { // Make the function asynchronous
        const parts = data.user.split(' ');
        const firstname = parts[0];
        const lastname = parts.slice(1).join(' ');
        try {
            // Make the Axios GET request
            const response = await axios.get(!lastname ? `https://api.kvægapv.eu/search?cvr=${data.user}` : `https://api.kvægapv.eu/search?firstName=${firstname}&lastName=${lastname}`);
            const response2 = await axios.get(`https://api.kvægapv.eu/getAllFarmers`);
            setSearchResults(getAll ? response2.data : response.data); // Store the response data in state
            console.log(getAll ? response2.data : response.data);
        } catch (error) {
            console.error("Error fetching search results:", error);
        }
    };

    return (
        <SearchStyle>
            {!searchResults && <h2 style={{position: "absolute", left: "50vw", top: "40vh", right: "50vh"}}>Gør tingene klar...</h2>}
            <form onSubmit={handleSubmit(submitForm)}>
                <input type="text" name="user" id='user' placeholder='Søg..' {...register('user')}/>
                {errors.user && <span>Du kan ikke søge på ingenting!</span>}
                <article>
                    <button type='reset' onClick={() => setSearchResults([])}>Slet alt</button>
                    <button type='submit' onClick={() => setGetAll(true)}>Vis alle</button>
                    <button type='submit' onClick={() => setGetAll(false)}>Søg</button>
                </article>
            </form> 
            <h2>Resultater</h2>
            <section className='result'>
                {searchResults.length > 0 ? searchResults.map((item, index) => (
                    <p key={index} onClick={() => navigate(`/clientlookup/${item.id}`)}>{item.firstname} {item.lastname}</p>
                )) : <h2 style={{borderBottom: "solid white 2px"}}>Intet resultat</h2>}
            </section>
        </SearchStyle>
    );
}

export default Search;
