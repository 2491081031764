import { styled } from "styled-components";

export const FrontpageStyle = styled.section`
display: grid;

button{
margin: 1vh 4vw;
background-color: #007297;
border-radius: 6px;
border: none;
height: 20vh;
font-size: 21px;
font-weight: 400;
color: white;
}

div{
    height:98%;
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-gap: 1vw;
    button{
        height: 100%;
    }
}


@media screen and (min-width: 768px) {
    margin-left: 12.5rem;
    margin-top: 1vh;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2vh 1vw;
    width: 85vw;
    button{
        margin: 0vh 0vw;
        background-color: #007297;
        border-radius: 6px;
        border: none;
        height: 48vh;
        width: 100%;
        font-size: 40px;
        font-weight: 400;
        color: white;
        }
    }
    
`