import React, { useEffect, useState } from 'react';
import { LoginFromStyle } from './LoginForm.style';
import { useForm } from 'react-hook-form';
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import image from "../../Assets/fjordland_logo_fritlagt.png";
import SuccesCheckmarkLogin from '../SuccesCheckmarkLogin/SuccesCheckmarkLogin';

function LoginForm() {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const navigate = useNavigate();
    const [emailError, setEmailError] = useState(false);
    const [loginError, setLoginError] = useState(false);
    const [bannedError, setBannedError] = useState(false);
    const [showCheckmark, setShowCheckmark] = useState(false);

    useEffect(() => {
        if (sessionStorage.getItem('user')) {
            navigate('/dashboard');
        }
    }, [navigate]);

    const submitForm = async (data) => {

        try {
            console.log('Submitting data:', data); // Log data to verify it is correct

            const response = await axios.post('https://api.kvægapv.eu/login', {
                email: data.email,
                password: data.password
            });
            console.log(response);
            if (response.status === 200) {
                setShowCheckmark(true)
                setTimeout(() => {
                                 sessionStorage.setItem('user', JSON.stringify(response.data));
                if (data.email.includes('@kvxp.dk') || data.email.includes('@fjordland.dk')) {
                    navigate('/dashboard'); 
                } else {
                    navigate(`/clientpage/${response.data.id}`);
                }  
                }, 1000);

            } else if (response.data.banned) {
                setBannedError(true);
            } else {
                setLoginError(true);
            }
        } catch (error) {
            setLoginError(true);
            console.error('Login error:', error);
        }
    };

    return (
        <LoginFromStyle>
            <form onSubmit={handleSubmit(submitForm)}>
                <h1>AgroAPV</h1>
                <div className='headingDiv'>
                    <img src={image} alt="logo" />
                </div>
                <label htmlFor="email">Brugernavn</label>
                <input type="text" name="email" id='email' placeholder='Email' {...register('email', { required: true })} />
                <label htmlFor="password">Kodeord</label>
                <input type="password" id='password' name="password" placeholder='Password' {...register('password', { required: true })} />
                <p id="forgotPass" >Glemt dit kodeord?</p>
                {errors.email && <span>Email skal udfyldes!</span>}
                {emailError && <span>Din email er ikke fra kvægexperten eller fjordland!</span>}
                {errors.password && <span>Kodeord skal udfyldes!</span>}
                {loginError && <span id='loginError'>Kodeord eller brugernavn findes ikke eller er forkert</span>}
                {bannedError && <span id='banned'>Din konto er ikke aktiv. Hvis du mener dette er en fejl kan du kontakte en administrator.</span>}
                <button type='submit'>Login</button>
                {showCheckmark ? <SuccesCheckmarkLogin /> : null}
            </form>
        </LoginFromStyle>
    );
}

export default LoginForm;
