import { useEffect, useState } from "react";
import Approuter from "./Approuter/Approuter";
import Header from "./Components/Header/Header";
import GlobalStyle from "./Global.style";
import { useLocation, useNavigate } from "react-router-dom";

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
  const navigate = useNavigate(); // Get the navigation function
  const location = useLocation();
console.log("app");
console.log(location.pathname.includes("readapv"));
 useEffect(() => {

   let interval = setInterval(() => {
     if (sessionStorage.getItem('user')) {
       setLoggedIn(true)
       clearInterval(interval)
   }
   }, 500);
 }, []);

  useEffect(() => {


    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="App">
      <GlobalStyle />
      
      <main style={{
        display: 'flex',
        flexDirection: isDesktop ? 'row' : 'column', // Side by side on desktop, stacked on mobile
      }}>
        {loggedIn ? <Header /> : null}
        {location.pathname.includes("readapv") ? <Header /> : null}
        <Approuter />
      </main>
    </div>
  );
}

export default App;
