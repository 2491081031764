import { styled } from "styled-components";

export const PreAPVStyle = styled.article`
margin-left: 4vw;
margin-right: 4vw;  

form{
display: grid;   
span{
    text-align: center;
    font-weight: bold;
    margin-top: 1vh;
}
section{
    display: grid;
    grid-template-columns: 1fr;
}

.cvrInput
,.dateInput
,.emailInput
,.phoneInput{
    margin-bottom: 2vh;
    text-align: center;
    background-color: #007297;
    border: none;
    border-radius: 6px;
    padding: 2vh 0;
    color: white;
    font-weight: bold;
    font-size: 17px;
    span{
        display: block;
    }
        input{
        text-align: center;
        color: white;
    font-size: 25px;
    border: none;
    border-bottom: solid white 2px;
    padding-bottom: 1vh;
    background-color: #007297;
    width: 80%;

}
input::placeholder{
    color: #FFFFFF;
    opacity: 54%;
}
}
.clientInput{
    margin-bottom: 2vh;
    text-align: center;
    height: auto;
    background-color: #007297;
    border: none;
    border-radius: 6px;
    padding: 2vh 0;
    color: white;
    font-weight: bold;
    font-size: 17px;
    input{
        text-align: center;
        color: white;
    font-size: 25px;
    border: none;
    border-bottom: solid white 2px;
    padding-bottom: 1vh;
    background-color: #007297;
    width: 50%;

}
select{
    text-align: center;
        color: white;
    font-size: 25px;
    border: none;
    border-bottom: solid white 2px;
    padding-bottom: 1vh;
    background-color: #007297;
    width: fit-content;
}
}
.listSec{
    margin-bottom: 2vh;
    text-align: center;
    background-color: #007297;
    border: none;
    border-radius: 6px;
    padding: 2vh 0;
    color: white;
    font-weight: bold;
    font-size: 17px;
    height: 30vh;
    overflow: auto;
    h2{
height: 10vh;
    }
    select{
       margin-bottom: 5vh;
    }
}
.listDiv{
    display: flex;
    justify-content: space-between;
    text-align: left;
    border-bottom: solid white 1px;
    padding-bottom: 2vh;
    padding-top: 2vh;
    p{
margin-left: 10vw;
    }
    input{
        margin-right: 8vw;
    }
}
.bilagSec{

    text-align: center;
    background-color: #007297;
    border: none;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    padding: 2vh 0;
    color: white;
    font-weight: bold;
    font-size: 17px;
    height: 30vh;
    overflow: auto;
    h2{
        height: 10vh;
    }
}
.bilagDiv{
    display: grid;
    grid-template-columns: 80% 20%;
    justify-items: center;
    border-bottom: solid white 1px;
    padding-bottom: 2vh;
    padding-top: 2vh;
    text-align: center;
    p{
        margin-left: 8vh;
    }
    button{
        margin-right: 6vw;
        border: none;
        width: 20vw;
        height: 4vh;
        border-radius: 6px;
    }
}
.uploadBtn{
    border: none;
    background-color: #007297;
    color: #FFFFFF;
    font-size: 21px;
    font-weight: bold;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    height: 6vh;
    padding: 2vh, 4vw;
}


.btnArt{
    width: 96%;
    display: grid;
    grid-template-columns: 30% 70%;
    grid-gap: 2vw;
    margin-top: 2vh;
    margin-left: 0vw;
    button{
    background-color: #007297;
    border: none;
    border-radius: 6px;
    padding: 2vh 0;
    color: white;
    font-weight: bold;
    font-size: 17px;
    cursor: pointer;
    margin-bottom: 2vh;
    width: 100%;

}
.clearAll{
    background-color: #CF4040;
}
}



}

@media screen and (min-width: 768px) {

    margin: 1vh 0;
    form{
        display: grid;   
        grid-template-columns: 1fr 1fr;
        grid-gap: 1vw;
        margin-left: 12.5rem;
        span{
            text-align: center;
            font-weight: bold;
            margin-top: 1vh;
        }
        .btnArt{
    width: 88%;
    display: grid;
    grid-template-columns: 20% 20% 60%;
    grid-gap: 2vw;
    margin-top: 2vh;
    margin-left: 0vw;
    button{
    background-color: #007297;
    border: none;
    border-radius: 6px;
    padding: 2vh 0;
    color: white;
    font-weight: bold;
    font-size: 17px;
    cursor: pointer;
    margin-bottom: 2vh;
    width: 100%;

}
}
    }

    .listSec{
        margin-bottom: 2vh;
        background-color: #007297;
        border: none;
        border-radius: 6px;
        padding: 2vh 0;
        color: white;
        font-weight: bold;
        font-size: 17px;
        height: 50vh;
        h2{
            height: 10vh;
        }
    }
    
    .listArt{

        width: fit-content;
        margin-left: 0;
    }
    
    .listDiv{
        display: flex;
        justify-content: space-between;
        border-bottom: solid white 1px;
        padding-bottom: 2vh;
        padding-top: 2vh;
        margin-left: 0;
        p{
            margin-right: 8vw;
            margin-left: 0;
            
        }
        input{
            margin-right: 8vw;
        }
    }
}
`