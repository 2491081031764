import React from 'react';
import { QRCode, QRCodeSVG } from 'qrcode.react';
import { QrCodeComponentStyle } from './QrCodeComponent.style';

const QRCodeComponent = ({ data }) => {
  const baseUrl = "https://xn--kvgapv-qua.eu"
  return (
        <QrCodeComponentStyle>
      <QRCodeSVG value={baseUrl + data} size={100} bgColor="#ffffff" fgColor="#000000" />
      </QrCodeComponentStyle>
  );
};

export default QRCodeComponent;
