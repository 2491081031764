import React, { useEffect, useState } from 'react';
import { ForgotPasswordStyle } from './ForgotPassword.style';
import { useForm } from 'react-hook-form';
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import image from "../../Assets/fjordland_logo_fritlagt.png";

function ForgotPassword() {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const navigate = useNavigate();
    const [emailError, setEmailError] = useState(false);
    const [loginError, setLoginError] = useState(false);
    const [bannedError, setBannedError] = useState(false);

    const submitForm = async (data) => {

        try {
            console.log('Submitting data:', data); // Log data to verify it is correct

            const response = await axios.post('https://api.kvægapv.eu/forgot-password', {
                email: data.email,
            });
            alert(`Nulstillings email sendt til ${data.email}`)
        } catch (error) {
            alert('Der skete en fejl, prøv igen senere..')
            console.error('Login error:', error);
        }
    };

    return (
        <ForgotPasswordStyle>
            <form onSubmit={handleSubmit(submitForm)}>
                <h1>AgroAPV</h1>
                <div className='headingDiv'>
                    <img src={image} alt="logo" />
                </div>
                <label htmlFor="email">Din email</label>
                <input type="text" name="email" id='email' placeholder='Email' {...register('email', { required: true })} />
                {errors.email && <span>Email skal udfyldes!</span>}
                <button type='submit'>Send nulstillings email</button>
                <button id='forgotPassBackBtn'>Tilbage</button>
                {/* <NavLink to={'/dashboard'}>Konsulent</NavLink>
                <NavLink to={'/clientpage'}>Landmand</NavLink> */}
            </form>
        </ForgotPasswordStyle>
    );
}

export default ForgotPassword;
