import React, { useState } from 'react'
import { FrontpageStyle } from './Frontpage.style'
import { useNavigate } from 'react-router-dom' // Import useNavigate
import Header from '../../Components/Header/Header'

function Frontpage() {
  const navigate = useNavigate(); // Get the navigation function
const [loggedIn, setLoggedIn] = useState(false)
setTimeout(() => {
  setLoggedIn(true)
}, 300);
  return (
    loggedIn ? <FrontpageStyle>

      <button className='Btn1' onClick={() => navigate('/newapv1')}> Opret APV</button>
        <button className='Btn2' onClick={() => navigate('/newclient')}> Opret kunde</button>
        {/* {JSON.parse(sessionStorage.getItem('user')).isAdmin ? <button className='Btn2' onClick={() => navigate('/newclient')}> Opret konsulent</button> : null} */}
      
      <button className='Btn3' onClick={() => navigate('/search')}> Søg kunde</button>
      <button className='Btn4' onClick={() => navigate('/history')}> Historik</button>
    </FrontpageStyle> : null
  )
}

export default Frontpage
