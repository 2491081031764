import React, { useEffect, useState } from 'react'
import { SuccesCheckmarkStyle } from './SuccesCheckmark.style'

function SuccesCheckmark() {
    const [showCheckIcon, setShowCheckIcon] = useState(true);

    const handleClick = () => {
      setShowCheckIcon(false);
      setTimeout(() => {
        setShowCheckIcon(true);
        setTimeout(() => {
            setShowCheckIcon(false);
            
          }, 2000);
      }, 10);
    };

    useEffect(() => {
        handleClick()
    }, [])
  return (
    <SuccesCheckmarkStyle>
        {/* <button onClick={handleClick}>Click Me</button> */}
{        showCheckIcon ?    <div className="success-checkmark">
  <div className="check-icon">
    <span className="icon-line line-tip"></span>
    <span className="icon-line line-long"></span>
    <div className="icon-circle"></div>
    <div className="icon-fix"></div>
  </div>
</div> : null}
    </SuccesCheckmarkStyle>

  )
}

export default SuccesCheckmark